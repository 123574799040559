// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bedrift-og-ergonomi-jsx": () => import("./../../../src/pages/bedrift-og-ergonomi.jsx" /* webpackChunkName: "component---src-pages-bedrift-og-ergonomi-jsx" */),
  "component---src-pages-behandling-tsx": () => import("./../../../src/pages/behandling.tsx" /* webpackChunkName: "component---src-pages-behandling-tsx" */),
  "component---src-pages-behandlingsmetoder-dns-tsx": () => import("./../../../src/pages/behandlingsmetoder/dns.tsx" /* webpackChunkName: "component---src-pages-behandlingsmetoder-dns-tsx" */),
  "component---src-pages-behandlingsmetoder-ischemisk-trykk-tsx": () => import("./../../../src/pages/behandlingsmetoder/ischemisk-trykk.tsx" /* webpackChunkName: "component---src-pages-behandlingsmetoder-ischemisk-trykk-tsx" */),
  "component---src-pages-behandlingsmetoder-klassisk-rehabilitering-tsx": () => import("./../../../src/pages/behandlingsmetoder/klassisk-rehabilitering.tsx" /* webpackChunkName: "component---src-pages-behandlingsmetoder-klassisk-rehabilitering-tsx" */),
  "component---src-pages-behandlingsmetoder-leddjustering-tsx": () => import("./../../../src/pages/behandlingsmetoder/leddjustering.tsx" /* webpackChunkName: "component---src-pages-behandlingsmetoder-leddjustering-tsx" */),
  "component---src-pages-behandlingsmetoder-mdt-tsx": () => import("./../../../src/pages/behandlingsmetoder/mdt.tsx" /* webpackChunkName: "component---src-pages-behandlingsmetoder-mdt-tsx" */),
  "component---src-pages-behandlingsmetoder-naalebehandling-tsx": () => import("./../../../src/pages/behandlingsmetoder/naalebehandling.tsx" /* webpackChunkName: "component---src-pages-behandlingsmetoder-naalebehandling-tsx" */),
  "component---src-pages-behandlingsmetoder-trykkbolgeterapi-tsx": () => import("./../../../src/pages/behandlingsmetoder/trykkbolgeterapi.tsx" /* webpackChunkName: "component---src-pages-behandlingsmetoder-trykkbolgeterapi-tsx" */),
  "component---src-pages-behandlingstilbud-bekkensmerter-bekkenlosning-tsx": () => import("./../../../src/pages/behandlingstilbud/bekkensmerter-bekkenlosning.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-bekkensmerter-bekkenlosning-tsx" */),
  "component---src-pages-behandlingstilbud-brystryggsmerter-tsx": () => import("./../../../src/pages/behandlingstilbud/brystryggsmerter.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-brystryggsmerter-tsx" */),
  "component---src-pages-behandlingstilbud-gravide-tsx": () => import("./../../../src/pages/behandlingstilbud/gravide.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-gravide-tsx" */),
  "component---src-pages-behandlingstilbud-hofteplager-tsx": () => import("./../../../src/pages/behandlingstilbud/hofteplager.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-hofteplager-tsx" */),
  "component---src-pages-behandlingstilbud-kneplager-leddbaansskade-tsx": () => import("./../../../src/pages/behandlingstilbud/kneplager-leddbaansskade.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-kneplager-leddbaansskade-tsx" */),
  "component---src-pages-behandlingstilbud-korsryggsmerter-tsx": () => import("./../../../src/pages/behandlingstilbud/korsryggsmerter.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-korsryggsmerter-tsx" */),
  "component---src-pages-behandlingstilbud-migrene-tsx": () => import("./../../../src/pages/behandlingstilbud/migrene.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-migrene-tsx" */),
  "component---src-pages-behandlingstilbud-nakkesmerter-tsx": () => import("./../../../src/pages/behandlingstilbud/nakkesmerter.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-nakkesmerter-tsx" */),
  "component---src-pages-behandlingstilbud-overtraakk-tsx": () => import("./../../../src/pages/behandlingstilbud/overtraakk.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-overtraakk-tsx" */),
  "component---src-pages-behandlingstilbud-senebetennelse-tsx": () => import("./../../../src/pages/behandlingstilbud/senebetennelse.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-senebetennelse-tsx" */),
  "component---src-pages-behandlingstilbud-skiveutglidning-tsx": () => import("./../../../src/pages/behandlingstilbud/skiveutglidning.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-skiveutglidning-tsx" */),
  "component---src-pages-behandlingstilbud-skuldersmerter-tsx": () => import("./../../../src/pages/behandlingstilbud/skuldersmerter.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-skuldersmerter-tsx" */),
  "component---src-pages-behandlingstilbud-spedbarn-barn-og-ungdom-tsx": () => import("./../../../src/pages/behandlingstilbud/spedbarn-barn-og-ungdom.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-spedbarn-barn-og-ungdom-tsx" */),
  "component---src-pages-behandlingstilbud-spenningshodepine-tsx": () => import("./../../../src/pages/behandlingstilbud/spenningshodepine.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-spenningshodepine-tsx" */),
  "component---src-pages-behandlingstilbud-utstralende-smerter-tsx": () => import("./../../../src/pages/behandlingstilbud/utstralende-smerter.tsx" /* webpackChunkName: "component---src-pages-behandlingstilbud-utstralende-smerter-tsx" */),
  "component---src-pages-forsikring-tsx": () => import("./../../../src/pages/forsikring.tsx" /* webpackChunkName: "component---src-pages-forsikring-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-klinikk-tsx": () => import("./../../../src/pages/klinikk.tsx" /* webpackChunkName: "component---src-pages-klinikk-tsx" */),
  "component---src-pages-om-kiropraktikk-jsx": () => import("./../../../src/pages/om-kiropraktikk.jsx" /* webpackChunkName: "component---src-pages-om-kiropraktikk-jsx" */),
  "component---src-pages-om-oss-tsx": () => import("./../../../src/pages/om-oss.tsx" /* webpackChunkName: "component---src-pages-om-oss-tsx" */),
  "component---src-pages-personlig-trening-tsx": () => import("./../../../src/pages/personlig-trening.tsx" /* webpackChunkName: "component---src-pages-personlig-trening-tsx" */),
  "component---src-pages-priser-tsx": () => import("./../../../src/pages/priser.tsx" /* webpackChunkName: "component---src-pages-priser-tsx" */)
}

